import { useCallback, useMemo } from "react"

import { useParams } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { useIsMobileDevice } from "@l2r-front/l2r-ui"
import { useNavigateWithSearchParams } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { DETAIL_TAB_PARAM_KEY } from "../../../../common/constants/tabKeys"
import { usePoleWithVerticalSigns } from "../../hooks/queries/verticalSigning/usePoleWithVerticalSigns"
import { VerticalSignPoleDetailsSidebarContent } from "../VerticalSignPoleDetailsSidebarContent"
import * as Styled from "./VerticalSignPoleDetailsSidebar.styled"

export function VerticalSignPoleDetailsSidebar() {

    const navigate = useNavigateWithSearchParams()
    const { poleId, road } = useParams()
    const { data } = usePoleWithVerticalSigns(poleId)
    const { t } = useTranslation(I18N_NAMESPACE)
    const isMobile = useIsMobileDevice()

    const title = useMemo(() => {
        if (data) {
            return data.vertical_signs.filter(vertical_sign => vertical_sign.is_main).length === 1 ? data.vertical_signs[0].name
                : t(I18N_NAMESPACE, "containers.sidebarVerticalSignDetail.multiSigns.title")
        }
        return ""
    }, [data, t])

    const navigateBack = useCallback(() => {
        navigate(`../${road}`, [DETAIL_TAB_PARAM_KEY])
    }, [navigate, road])

    return <Styled.SidebarContent id="verticalSigning-sidebar"
        onBack={navigateBack}
        isMobile={isMobile}
        title={title}
    >
        <VerticalSignPoleDetailsSidebarContent />
    </Styled.SidebarContent>
}