import { useContext } from "react"

import { HorizontalSigningStateContext, HorizontalSigningDispatchContext } from "./HorizontalSigningContext.context"

export const useHorizontalSigningStateContext = () => {
    const context = useContext(HorizontalSigningStateContext)
    if (context === undefined) {
        throw new Error("useHorizontalSigningStateContext must be used within an HorizontalSigningStateContext")
    }
    return context
}

export const useHorizontalSigningDispatchContext = () => {
    const context = useContext(HorizontalSigningDispatchContext)
    if (context === undefined) {
        throw new Error("useHorizontalSigningDispatchContext must be used within an HorizontalSigningDispatchContext")
    }
    return context
}

export const useHorizontalSigningContext = () => {
    return [useHorizontalSigningStateContext(), useHorizontalSigningDispatchContext()]
}
