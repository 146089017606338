import { useEffect, useMemo, useCallback } from "react"

import * as turf from "@turf/turf"
import * as htmlToImage from "html-to-image"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { Source, useMapDispatchContext } from "@l2r-front/l2r-map"
import { useTheme } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { DEFAULT_SIGN_CODE, DEFAULT_SIGN_ICON } from "../../constants/defaultIcon"
import { HORIZONTAL_SIGNING_CONDITIONS_VALUES } from "../../constants/horizontalSigningConditions"
import { useHorizontalSigningStateContext } from "../../contexts/HorizontalSigningContext"
import { useHorizontalSigns } from "../../hooks/queries/horizontalSigning/useHorizontalSigns"
import { useHorizontalSignTypesInProject } from "../../hooks/queries/horizontalSigning/useHorizontalSignTypesInProject"
import { findTypeByCode } from "../../utils/findTypeByCode"

const ICON_SIZE = 60
export const ENABLED_STATE = "1"
export const DISABLED_STATE = "0"

export function HorizontalSigningSource(props) {

    const { t } = useTranslation(I18N_NAMESPACE)
    const { getMapRef, setError: setMapError } = useMapDispatchContext()
    const theme = useTheme()
    const { descendantCodes, filters } = useHorizontalSigningStateContext()

    const { data: horizontalSignTypes } = useHorizontalSignTypesInProject()

    const { data } = useHorizontalSigns({
        as_geojson: true,
    }, {
        onError: () => setMapError(true),
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.horizontalSignsLayer.error"),
    })

    const createMapIcon = useCallback((code, icon, condition, state, map, theme) => {
        const color = theme.palette[HORIZONTAL_SIGNING_CONDITIONS_VALUES?.[condition]?.color]?.main
        const opacity = state === ENABLED_STATE ? 1 : 0.5
        const iconId = code + "#" + condition + "#" + state
        const container = document.createElement("div")
        container.innerHTML = `
            <div style="background-color: white; height: 100%; width: 100%; border-radius: 50%;">
                <div style="background-color: ${color}; opacity:${opacity}; height: 100%; width: 100%; border-radius: 50%; position:absolute;top:0px;left:0px"></div>
                <div style="background-color: white; height: calc(100% - 20px); width: calc(100% - 20px); border-radius: 50%; position:absolute;top:10px;left:10px">
                    <img src=${icon} style="width:calc(100%); height:calc(100%); position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);object-fit: contain; opacity:${opacity};" />
                </div>
            </div>
        `

        htmlToImage.toPng(container, {
            height: ICON_SIZE,
            width: ICON_SIZE,
            canvasHeight: ICON_SIZE,
            canvasWidth: ICON_SIZE,
            pixelRatio: 1,
            skipFonts: true,
        })
            .then(function (dataUrl) {
                window.generatedIcons[iconId] = dataUrl
                map.loadImage(
                    dataUrl,
                    (error, icon) => {
                        if (error) {
                            return console.error(error)
                        }

                        if (!map.hasImage(iconId)) {
                            map.addImage(iconId, icon)
                        }
                    },
                )
            })
            .catch(() => {
                createMapIcon(code, DEFAULT_SIGN_ICON, condition, state, map, theme)
            })
    }, [])

    useEffect(() => {
        const map = getMapRef()?.getMap()

        if (!map || !theme) {
            return
        }

        if (!window.generatedIcons) {
            window.generatedIcons = {}
        }

        Object.keys(HORIZONTAL_SIGNING_CONDITIONS_VALUES).forEach(condition => {
            [ENABLED_STATE, DISABLED_STATE].forEach(state => {
                const iconId = DEFAULT_SIGN_CODE + "#" + condition + "#" + state
                if (!window.generatedIcons[iconId]) {
                    createMapIcon(DEFAULT_SIGN_CODE, DEFAULT_SIGN_ICON, condition, state, map, theme)
                }
            })
        })
    }, [getMapRef, theme, createMapIcon])

    useEffect(() => {
        const map = getMapRef()?.getMap()

        if (!map || !theme || !horizontalSignTypes) {
            return
        }

        const loadIcon = (e) => {
            const iconId = e.id
            const [typeCode, condition, state] = iconId.split("#")

            if (!typeCode || Object.keys(HORIZONTAL_SIGNING_CONDITIONS_VALUES).indexOf(condition) < 0) {
                return
            }

            if (!window.generatedIcons) {
                window.generatedIcons = {}
            }

            if (window.generatedIcons[iconId]) {
                map.loadImage(
                    window.generatedIcons[iconId],
                    (error, icon) => {
                        if (error) {
                            return console.error(error)
                        }

                        if (!map.hasImage(iconId)) {
                            map.addImage(iconId, icon)
                        }
                    },
                )
            } else {
                const icon = typeCode === DEFAULT_SIGN_CODE ? DEFAULT_SIGN_ICON :
                    findTypeByCode(typeCode, horizontalSignTypes)?.icon || DEFAULT_SIGN_ICON
                createMapIcon(typeCode, icon, condition, state, map, theme)
            }
        }

        map?.on("styleimagemissing", loadIcon)

        return () => map?.off("styleimagemissing", loadIcon)

    }, [getMapRef, theme, horizontalSignTypes, createMapIcon])

    // Need to copy id into properties because of that mapbox issue: 
    // link:https://github.com/mapbox/mapbox-gl-js/issues/2716
    const sanitizedData = useMemo(() => {
        if (!data || !horizontalSignTypes) {
            return null
        }

        const dataFeatures = []
        turf.geomEach(data, (currentGeometry, featureIndex, featureProperties, featureBBox, featureId) => {
            const feature = data.features[featureIndex]
            const sign = featureProperties
            const isSelectedType = descendantCodes.includes(sign.horizontal_sign_type)
            const isSelectedCondition = filters?.conditions ? (filters.conditions).includes(sign.condition)
                : true

            if (isSelectedType && isSelectedCondition) {
                dataFeatures.push({
                    id: featureId,
                    bbox: featureBBox,
                    geometry: currentGeometry,
                    properties: {
                        ...featureProperties,
                        globalCondition: sign.condition || -1,
                        globalIcon: sign.horizontal_sign_type,
                        horizontal_signs: sign,
                        linearLocation: feature.linear_location,
                        uuid: featureId,
                    },
                })
            }
        })
        return turf.featureCollection(dataFeatures)
    }, [data, descendantCodes, filters, horizontalSignTypes])

    if (!sanitizedData) {
        return null
    }

    return (
        <Source {...props} type="geojson" data={sanitizedData} tolerance={.001} />
    )
}