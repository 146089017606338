import { useAuthenticatedMutation } from "@l2r-front/l2r-auth"

export function useHorizontalSigningMutation(
    url,
    axiosFn,
    queryParameters,
    config = {},
) {
    const apiUrl = `horizontal_signing/${url}`

    return useAuthenticatedMutation(
        apiUrl,
        axiosFn,
        queryParameters,
        config,
    )
}