import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { Modal, useIsMobileDevice } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { VerticalSignDetailsFormPost } from "../../forms/VerticalSignDetailsForm"
import * as Styled from "./VerticalSignDetailsEditModal.styled"

export const VerticalSignDetailsEditModal = (props) => {

    const {
        className,
        signId,
        poleId,
        open,
        onClose,
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)
    const isMobile = useIsMobileDevice()

    return <Modal
        id="verticalSign-edit-modal"
        className={className}
        open={open}
        onClose={onClose}
    >
        <Styled.Box isMobile={isMobile}>
            <Styled.Title variant="H1">{t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.title")}</Styled.Title>
            <Styled.IconButton id="close-button" onClick={onClose}>
                <Styled.CloseIcon />
            </Styled.IconButton>
            <VerticalSignDetailsFormPost close={onClose} signId={signId} poleId={poleId} />
        </Styled.Box>
    </Modal>
}

VerticalSignDetailsEditModal.propTypes = {
    className: PropTypes.string,
    signId: PropTypes.string.isRequired,
    poleId: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
}
