import { useMemo } from "react"

import { useParams } from "react-router-dom"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { useVerticalSigningStateContext } from "../../contexts/VerticalSigningContext"
import { useVerticalSigningCategoriesStats } from "../../hooks/queries/verticalSigning/useVerticalSigningCategoriesStats"
import { useVerticalSignTypes } from "../../hooks/queries/verticalSigning/useVerticalSignTypes"
import * as Styled from "./VerticalSignsCount.styled"

export function VerticalSignsCount(props) {

    const {
        className,
        displayTotalCount,
        isMainCount,
        signType,
    } = props

    const { road } = useParams()
    const { filters } = useVerticalSigningStateContext()
    const apiFilters = {
        ...(filters?.conditions?.length && { condition: filters.conditions }),
        ...(road && { vertical_sign_pole__linear_location_road: road }),
    }

    const {
        data: dataWithFilters,
        isError: dataWithFiltersError,
        isLoading: dataWithFiltersLoading,
    } = useVerticalSigningCategoriesStats({}, { ...apiFilters })

    const {
        data: dataWithoutConditionFilters,
        isError: dataWithoutConditionFiltersError,
        isLoading: dataWithoutConditionFiltersLoading,
    } = useVerticalSigningCategoriesStats({}, { ...(road && { vertical_sign_pole__linear_location_road: road }) })

    const {
        data: verticalSignTypes,
        isError: isErrorVerticalSignTypes,
        isLoading: isLoadingVerticalSignTypes,
    } = useVerticalSignTypes()

    const calculatedCount = useMemo(() => {
        if (dataWithFilters && verticalSignTypes) {
            return verticalSignTypes.reduce((acc, verticalSignType) => {
                return acc + dataWithFilters.count[verticalSignType.code]
            }, 0)
        }
    }, [dataWithFilters, verticalSignTypes])

    if (dataWithFiltersError || dataWithoutConditionFiltersError || isErrorVerticalSignTypes) {
        return null
    }

    if (dataWithFiltersLoading || dataWithoutConditionFiltersLoading || isLoadingVerticalSignTypes) {
        return <Styled.CatalogItemsCountSkeleton className={className} isMainCount={isMainCount} />
    }

    return <Styled.CatalogItemsCount
        className={className}
        filteredCount={signType ? dataWithFilters.count[signType] : calculatedCount}
        isMainCount={isMainCount}
        totalCount={displayTotalCount && dataWithoutConditionFilters?.count?.[signType]}
    />
}

VerticalSignsCount.propTypes = {
    className: PropTypes.string,
    displayTotalCount: PropTypes.bool,
    isMainCount: PropTypes.bool,
    signType: PropTypes.string,
}