import { useCallback, useEffect, useMemo } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { ListRoundedIcon, LocalSeeIcon, MapRoundedIcon } from "@l2r-front/l2r-icons"
import { MobileImageViewerContainer, useImageCollectionDispatchContext } from "@l2r-front/l2r-images"
import { MobileModulePage } from "@l2r-front/l2r-ui"
import { useUrlParams } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { PARAM_KEY_VERTICAL_SIGNING } from "../../../../common/constants/urlParams"
import { VerticalSignPoleDetailsSidebar } from "../../containers/VerticalSignPoleDetailsSidebar"
import { VerticalSigningMapLayersRouterMobile } from "../../routers/VerticalSigningMapLayersRouter"
import * as Styled from "./MobileVerticalSignPoleDetailsPage.styled"

export function MobileVerticalSignPoleDetailsPage() {

    const { t } = useTranslation(I18N_NAMESPACE)
    const { resetImageCollectionType } = useImageCollectionDispatchContext()
    const { setParam } = useUrlParams()

    const tabChangeCallback = (oldValue) => {
        if (oldValue === "tab-photo") {
            resetImageCollectionType()
        }
    }
    const roadwayListTabs = useMemo(() => ([
        {
            element: <VerticalSignPoleDetailsSidebar />,
            icon: <ListRoundedIcon />,
            id: "tab-vertical-signing",
            label: t(I18N_NAMESPACE, "pages.verticalSigning.tabs.signs"),
        },
        {
            element: <Styled.Map >
                <VerticalSigningMapLayersRouterMobile />
            </Styled.Map>,
            icon: <MapRoundedIcon />,
            id: "tab-map",
            label: t(I18N_NAMESPACE, "pages.verticalSigning.tabs.map"),
            fullScreen: true,
        },
        {
            element: <MobileImageViewerContainer />,
            icon: <LocalSeeIcon />,
            id: "tab-photo",
            label: t(I18N_NAMESPACE, "pages.verticalSigning.tabs.photo"),
            fullScreen: true,
        },
    ]), [t])

    const onImageClicked = useCallback(() => {
        setParam(PARAM_KEY_VERTICAL_SIGNING, "tab-photo")
    }, [setParam])

    useEffect(() => {
        document.addEventListener("imageClick", onImageClicked)

        return () => {
            document.removeEventListener("imageClick", onImageClicked)
        }
    }, [onImageClicked])

    return (
        <MobileModulePage
            paramKey={PARAM_KEY_VERTICAL_SIGNING}
            tabChangeCallback={tabChangeCallback}
            tabsConfig={roadwayListTabs}
        />
    )
}