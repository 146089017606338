import { useMatch } from "react-router-dom"

import * as Styled from "./Kpi.styled"

export const KpiCard = (props) => {
    const {
        className,
        path,
        children,
        ...cardProps
    } = props

    const match = useMatch("/:selectedNetwork/*")
    const selectedNetwork = match?.params?.selectedNetwork
    const fullPath = `/${selectedNetwork}/${path}`


    return (
        <Styled.KpiCard to={fullPath} className={className} {...cardProps}>
            {children}
        </Styled.KpiCard>
    )
}
