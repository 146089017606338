import { useParams } from "react-router-dom"

import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import * as Styled from "./HorizontalSignsSidebarComponents.styled"

export function HorizontalSignsSidebarComponents(props) {

    const { handleChangeFilter } = props

    const isMobile = useIsMobileDevice()
    const { road, code } = useParams()

    return <>
        {road && <Styled.RoadDetailInfo isMobile={isMobile} road={road} />}
        <Styled.HorizontalSignsGrading isMobile={isMobile} />
        <Styled.HorizontalSignTypeList
            baseHorizontalSignTypeCode={code}
            isMobile={isMobile}
            onChangeFilter={handleChangeFilter}
        />
    </>
}

HorizontalSignsSidebarComponents.propTypes = {
    handleChangeFilter: PropTypes.func,
}