import { verticalSigningQueryKeys } from "./queryKeys"
import { useVerticalSigningQuery } from "./useVerticalSigningQuery"

export function useVerticalSign(signId, parameters = {}, config = {}) {
    const url = `vertical_signs/${signId}/`

    const queryKey = verticalSigningQueryKeys.verticalSign(signId)

    return useVerticalSigningQuery(
        url,
        queryKey,
        parameters,
        config,
    )
}