import { Skeleton, styled, Typography } from "@mui/material"

export const Wrapper = styled("div", {
    shouldForwardProp: prop => prop !== "isMainCount",
})(({ isMainCount, theme }) => ({
    alignItems: "center",
    borderRadius: 100,
    border: isMainCount && `1px solid ${theme.palette["colors/gris/1000"].main}`,
    display: "flex",
    padding: isMainCount && theme.spacing(2),
}))

export const Text = styled(Typography)(({ theme }) => ({
    margin: theme.spacing(0, 1),
}))

export const CatalogItemsCountSkeleton = styled(Skeleton, {
    shouldForwardProp: prop => prop !== "isMainCount",
})(({ isMainCount, theme }) => ({
    height: 24,
    padding: isMainCount && theme.spacing(2),
    transform: "scale(1)",
    width: 42,
}))