import { styled } from "@l2r-front/l2r-ui"

import { ADDITIONAL_SIDEBAR_WIDTH } from "../../../../common/constants/layout"
import { HorizontalSignTypeList as L2RHorizontalSignTypeList } from "../HorizontalSignTypeList"

export const Container = styled("div")(() => ({
    height: "100%",
    overflowY: "auto",
}))

export const HorizontalSignTypeList = styled(L2RHorizontalSignTypeList)(({ theme }) => ({
    padding: theme.spacing(8),
}))

export const AdditionalSidebar = styled("div")(({ index }) => ({
    height: "calc(100% - 72px)",
    position: "fixed",
    left: `calc(500px + ${index * ADDITIONAL_SIDEBAR_WIDTH}px)`,
    top: 72,
    width: ADDITIONAL_SIDEBAR_WIDTH,
    overflowY: "auto",
}))
