import { useMemo } from "react"

import { useMatch } from "react-router-dom"

import { useVerticalSigningStateContext } from "../../../modules/verticalSigning/contexts/VerticalSigningContext"
import { APP_CITY } from "../../constants/appCity"
import { MODULE_VERTICAL_SIGNING } from "../../constants/modules"
import { CityContentRouter } from "../../routers/CityContentRouter"
import { CityDesktopMapRouter } from "../../routers/CityDesktopMapRouter"
import { SidebarRouter } from "../../routers/SidebarRouter"
import * as Styled from "./CityDesktopLayout.styled"

export function CityDesktopLayout() {
    const { ascendantCodes, descendantCodes } = useVerticalSigningStateContext()
    const matchDetail = useMatch(`:slugId/${APP_CITY}/${MODULE_VERTICAL_SIGNING}/:road/:poleId`)

    const sidebarCount = useMemo(() => {
        const hasAdditionalSidebar = ascendantCodes.length % 2 === 1 || descendantCodes.length > 1
        return matchDetail ? 1 : Math.floor(ascendantCodes.length / 2) + (hasAdditionalSidebar ? 1 : 0)
    }, [ascendantCodes, descendantCodes, matchDetail])

    return (
        <Styled.Wrapper>
            <SidebarRouter />
            <CityContentRouter />
            <Styled.Map sidebarCount={sidebarCount}>
                <CityDesktopMapRouter />
            </Styled.Map>
        </Styled.Wrapper>
    )
}
