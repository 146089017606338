import { keepPreviousData } from "@tanstack/react-query"

import { verticalSigningQueryKeys } from "./queryKeys"
import { useVerticalSigningQuery } from "./useVerticalSigningQuery"


export function useVerticalSigningCategoriesStats(config = {}, parameters = {}) {
    const url = "vertical_signs/stats/vertical_sign_types/"

    const queryKey = verticalSigningQueryKeys["stats_categories"]({ parameters })

    return useVerticalSigningQuery(
        url,
        queryKey,
        parameters,
        {
            ...config,
            placeholderData: keepPreviousData,
        },
    )
}