import { Button, styled, Typography } from "@mui/material"

import { CircleIcon } from "@l2r-front/l2r-icons"

import { styledForMobile } from "../../utils/styledForMobile"

const ICON_DIMENSION = 28
const ICON_MOBILE_DIMENSION = 20
const BUTTON_WIDTH = 130
const BUTTON_MOBILE_WIDTH = 103

export const CatalogItemsConditionCountButton = styledForMobile(Button)(({ isMobile, selected, theme }) => ({
    alignItems: "center",
    borderRadius: 12,
    border: selected ? `2px solid ${theme.palette["cta-bg/cta-bg-primary"].main}`
        : `1px solid ${theme.palette["colors/gris/200"].main}`,

    color: theme.palette["texts/text-primary"].main,
    display: "flex",
    justifyContent: "flex-start",
    margin: !selected && 1,
    padding: isMobile ? theme.spacing(2) : theme.spacing(2, 3),
    width: isMobile ? selected ? `calc(${BUTTON_MOBILE_WIDTH}px + 2px)` : BUTTON_MOBILE_WIDTH
        : selected ? `calc(${BUTTON_WIDTH}px + 2px)` : BUTTON_WIDTH,
}))

export const TextWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
})

export const Icon = styledForMobile(CircleIcon)(({ isMobile, theme }) => ({
    height: isMobile ? ICON_MOBILE_DIMENSION : ICON_DIMENSION,
    marginRight: theme.spacing(2),
    width: isMobile ? ICON_MOBILE_DIMENSION : ICON_DIMENSION,
}))

export const CountText = styled(Typography)({
    lineHeight: "21px",
})

export const StateText = styledForMobile(Typography)(({ isMobile }) => ({
    lineHeight: "20px",
    fontSize: isMobile && "14px",
}))