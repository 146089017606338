import { RoadDetailInfo as L2RSegmentDetailInfo } from "@l2r-front/l2r-networks"
import { styledForMobile } from "@l2r-front/l2r-ui"

import { HorizontalSignsGrading as L2RHorizontalSignsGrading } from "../../containers/HorizontalSignsGrading"
import { HorizontalSignTypeList as L2RHorizontalSignTypeList } from "../../containers/HorizontalSignTypeList"

export const RoadDetailInfo = styledForMobile(L2RSegmentDetailInfo)(({ isMobile, theme }) => ({
    padding: !isMobile && theme.spacing(0, 8, 0, 8),
    marginBottom: theme.spacing(isMobile ? 6 : 5),
}))

export const HorizontalSignTypeList = styledForMobile(L2RHorizontalSignTypeList)(({ isMobile, theme }) => ({
    padding: !isMobile && theme.spacing(8),
}))

export const HorizontalSignsGrading = styledForMobile(L2RHorizontalSignsGrading)(({ isMobile, theme }) => ({
    padding: isMobile ? theme.spacing(0, 0, 8, 0) : theme.spacing(0, 8),
}))