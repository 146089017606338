import { useMemo } from "react"

import { useMatch } from "react-router-dom"

import { MapLegend } from "@l2r-front/l2r-map"

import { useRoadwayLegend } from "../../../modules/roadway/hooks/useRoadwayLegend"
import { useRoadworksLegend } from "../../../modules/roadworks/hooks/useRoadworksLegend"
import { APP_ROAD } from "../../constants/appRoad"
import { MODULE_ROADWAY, MODULE_ROADWORKS } from "../../constants/modules"

export function RoadMapLegend() {

    const matchRoadway = useMatch(`:slug/${APP_ROAD}/${MODULE_ROADWAY}/*`)
    const matchRoadworks = useMatch(`:slug/${APP_ROAD}/${MODULE_ROADWORKS}/*`)

    const roadwayLegend = useRoadwayLegend()

    const roadworksLegend = useRoadworksLegend()

    const displayedLegend = useMemo(() => {
        if (matchRoadway) {
            return roadwayLegend
        }

        if (matchRoadworks) {
            return roadworksLegend
        }

        return null

    }, [matchRoadway, matchRoadworks, roadwayLegend, roadworksLegend])

    if (displayedLegend) {
        return <MapLegend displayedLegends={displayedLegend} />
    }

    return []
}