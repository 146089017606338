import { useNetworksStateContext } from "@l2r-front/l2r-networks"

import { verticalSigningQueryKeys } from "./queryKeys"
import { useVerticalSigningQuery } from "./useVerticalSigningQuery"


export function useVerticalSignPoles(parameters = {}, config = {}) {
    const url = "vertical_sign_poles/"
    const { selectedNetwork } = useNetworksStateContext()

    const queryParameters = {
        ...parameters,
        project: selectedNetwork.slug,
    }

    const queryKey = verticalSigningQueryKeys["layer"](queryParameters)

    return useVerticalSigningQuery(
        url,
        queryKey,
        queryParameters,
        {
            enabled: !!selectedNetwork,
            ...config,
        },
    )
}

export function useVerticalSignPole(poleId, parameters = {}, config = {}) {
    const url = `vertical_sign_poles/${poleId}/`
    const { selectedNetwork } = useNetworksStateContext()

    const queryParameters = {
        ...parameters,
        project: selectedNetwork.slug,
    }

    const queryKey = verticalSigningQueryKeys.pole(poleId, parameters)

    return useVerticalSigningQuery(
        url,
        queryKey,
        queryParameters,
        {
            enabled: !!selectedNetwork,
            ...config,
        },
    )
}