import { useNetworksStateContext } from "@l2r-front/l2r-networks"

import { horizontalSigningQueryKeys } from "./queryKeys"
import { useHorizontalSigningQuery } from "./useHorizontalSigningQueries"


export function useHorizontalSigns(parameters = {}, config = {}) {
    const url = "horizontal_signs/"
    const { selectedNetwork } = useNetworksStateContext()

    const queryParameters = {
        ...parameters,
        project: selectedNetwork.slug,
    }

    const queryKey = horizontalSigningQueryKeys["layer"](queryParameters)

    return useHorizontalSigningQuery(
        url,
        queryKey,
        queryParameters,
        {
            enabled: !!selectedNetwork,
            ...config,
        },
    )
}

export function useHorizontalSign(signId, parameters = {}, config = {}) {
    const url = `horizontal_signs/${signId}/`
    const { selectedNetwork } = useNetworksStateContext()

    const queryParameters = {
        ...parameters,
        project: selectedNetwork.slug,
    }

    const queryKey = horizontalSigningQueryKeys["feature"](signId, parameters)

    return useHorizontalSigningQuery(
        url,
        queryKey,
        queryParameters,
        {
            enabled: !!selectedNetwork,
            ...config,
        },
    )
}