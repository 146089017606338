import { Autocomplete, Chip, TextField } from "@mui/material"

import { PropTypes } from "@l2r-front/l2r-proptypes"

export const AutoCompleteWithChips = (props) => {

    const {
        label,
        id,
        maxLength,
        variant,
        ...autocompleteProps
    } = props

    const { getOptionLabel } = autocompleteProps

    return <Autocomplete
        {...autocompleteProps}
        multiple
        id={id}
        renderInput={(params) => (
            <TextField
                {...params}
                color="cta-bg/cta-bg-primary"
                label={label}
                inputProps={{ ...params.inputProps, maxLength }}
            />
        )}
        renderTags={(value, getTagProps) =>
            value.map((option, index) => (
                <Chip
                    {...getTagProps({ index })}
                    color="cta-bg/cta-bg-primary"
                    label={getOptionLabel ? getOptionLabel(option) : option}
                    variant={variant}
                />
            ))
        }
    />
}

AutoCompleteWithChips.defaultProps = {
    variant: "filled",
}

AutoCompleteWithChips.propTypes = {
    label: PropTypes.string,
    id: PropTypes.string,
    variant: PropTypes.string,
}
