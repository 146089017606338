import { useParams } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import {
    CatalogItemsGrading,
    CatalogItemsGradingError,
    CatalogItemsGradingSkeleton,
} from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { getVerticalSigningConditionsValues } from "../../constants/verticalSigningConditions"
import { useVerticalSigningDispatchContext, useVerticalSigningStateContext } from "../../contexts/VerticalSigningContext"
import { useVerticalSigningGrades } from "../../hooks/queries/verticalSigning/useVerticalSigningGrades"

export function VerticalSignsGrading(props) {
    const { road } = useParams()
    const { filters } = useVerticalSigningStateContext()
    const { setFilters } = useVerticalSigningDispatchContext()
    const { t } = useTranslation(I18N_NAMESPACE)
    const { data, isError, isLoading } = useVerticalSigningGrades({}, {
        ...(road && { vertical_sign_pole__linear_location_road: road }),
    })

    if (isError) {
        return <CatalogItemsGradingError
            errorText={t(I18N_NAMESPACE, "components.verticalSignsGrading.error")}
            {...props}
        />
    }

    if (!data && isLoading) {
        return <CatalogItemsGradingSkeleton {...props} />
    }

    return <CatalogItemsGrading
        activeConditionFilters={filters?.conditions}
        catalogItemsGradingSettings={getVerticalSigningConditionsValues(t)}
        count={data.count}
        onConditionFiltersChanged={(value) => setFilters({ condition: value })}
        {...props}
    />
}