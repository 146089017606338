import {
    CatalogItemsCount as UiCatalogItemsCount,
    CatalogItemsCountSkeleton as UiCatalogItemsCountSkeleton,
    styled,
} from "@l2r-front/l2r-ui"

export const CatalogItemsCount = styled(UiCatalogItemsCount)({
    marginLeft: "auto",
})

export const CatalogItemsCountSkeleton = styled(UiCatalogItemsCountSkeleton)({
    marginLeft: "auto",
})