import { Typography } from "@mui/material"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { I18N_NAMESPACE } from "../../constants/i18n"
import { CatalogListItem } from "../CatalogListItem"
import * as Styled from "./CatalogList.styled"

export function CatalogList(props) {

    const {
        ascendantCodes,
        baseItemCode,
        defaultIcon,
        className,
        data,
        emptyListText,
        filter,
        ItemsCountComponent,
        onClick,
        title,
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)

    return <div className={className}>
        <Styled.Header>
            <Typography variant="H2">
                {title}
            </Typography>
            <ItemsCountComponent signType={baseItemCode} isMainCount />
        </Styled.Header>
        {data?.length ? <Styled.List>
            {
                data.map((dataItem) => {
                    const children = dataItem.children.length ? dataItem.children.map(child => {
                        return <CatalogListItem
                            icon={child.children.length ? null : (child.icon ?? defaultIcon)}
                            key={child.code}
                            defaultIcon={defaultIcon}
                            expandable={false}
                            highlighted={filter !== child.code && (ascendantCodes || []).includes(child.code)}
                            label={child.name}
                            onClick={() => onClick(child.code)}
                            selected={filter === child.code}
                            ItemsCountComponent={<ItemsCountComponent signType={child.code} />}
                        />
                    })
                        : null

                    return <CatalogListItem
                        icon={dataItem.children.length ? null : (dataItem.icon ?? defaultIcon)}
                        defaultIcon={defaultIcon}
                        key={dataItem.code}
                        expandable={!!children}
                        children={children}
                        highlighted={filter !== dataItem.code && (ascendantCodes || []).includes(dataItem.code)}
                        label={dataItem.name}
                        onClick={() => onClick(dataItem.code)}
                        selected={filter === dataItem.code}
                        startOpen={children && (ascendantCodes || []).includes(dataItem.code)}
                        ItemsCountComponent={<ItemsCountComponent
                            displayTotalCount={!!dataItem.children.length}
                            signType={dataItem.code}
                        />}
                    />
                })
            }
        </Styled.List>
            : <Typography>
                {emptyListText ? emptyListText : t(I18N_NAMESPACE, "components.catalogList.emptyList")}
            </Typography>}
    </div>
}

CatalogList.propTypes = {
    ascendantCodes: PropTypes.arrayOf(PropTypes.string),
    baseItemCode: PropTypes.string,
    className: PropTypes.string,
    data: PropTypes.any.isRequired,
    defaultIcon: PropTypes.string,
    emptyListText: PropTypes.string,
    filter: PropTypes.oneOfType([PropTypes.string, () => null]),
    ItemsCountComponent: PropTypes.func,
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
}