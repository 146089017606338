import { Typography } from "@mui/material"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./CatalogItemsCount.styled"

export function CatalogItemsCount(props) {

    const {
        className,
        filteredCount,
        isMainCount,
        totalCount = null,
    } = props

    return <Styled.Wrapper className={className} isMainCount={isMainCount}>
        <Styled.Text variant="Regular">{filteredCount}</Styled.Text>
        {Number.isInteger(totalCount) ? <Typography variant="Small">/&nbsp;{totalCount}</Typography>
            : null}
    </Styled.Wrapper>
}

CatalogItemsCount.propTypes = {
    className: PropTypes.string,
    filteredCount: PropTypes.number.isRequired,
    isMainCount: PropTypes.bool,
    totalCount: PropTypes.number,
}