import { I18N_NAMESPACE } from "../../../common/constants/i18n"

export const HORIZONTAL_SIGNING_CONDITION_GOOD = 10
export const HORIZONTAL_SIGNING_CONDITION_MEDIUM = 20
export const HORIZONTAL_SIGNING_CONDITION_BAD = 30
export const HORIZONTAL_SIGNING_CONDITION_UNKNOWN = -1
export const HORIZONTAL_SIGNING_CONDITIONS = [
    HORIZONTAL_SIGNING_CONDITION_GOOD,
    HORIZONTAL_SIGNING_CONDITION_MEDIUM,
    HORIZONTAL_SIGNING_CONDITION_BAD,
    HORIZONTAL_SIGNING_CONDITION_UNKNOWN,
]

export const HORIZONTAL_SIGNING_CONDITIONS_VALUES = {
    [HORIZONTAL_SIGNING_CONDITION_BAD]: {
        backgroundColor: "colors/rouge/800",
        color: "error/main",
        label: "components.horizontalSignsCount.bad",
    },
    [HORIZONTAL_SIGNING_CONDITION_MEDIUM]: {
        backgroundColor: "colors/jaune/800",
        color: "DPE/orange",
        label: "components.horizontalSignsCount.medium",
    },
    [HORIZONTAL_SIGNING_CONDITION_GOOD]: {
        backgroundColor: "colors/vert logiroad/200",
        color: "objects/object-primary",
        label: "components.horizontalSignsCount.good",
    },
    [HORIZONTAL_SIGNING_CONDITION_UNKNOWN]: {
        color: "colors/gris/500",
        label: "",
    },
}

export const getHorizontalSigningConditionsValues = (t) => ({
    [HORIZONTAL_SIGNING_CONDITION_BAD]: {
        ...HORIZONTAL_SIGNING_CONDITIONS_VALUES[HORIZONTAL_SIGNING_CONDITION_BAD],
        label: t(I18N_NAMESPACE, HORIZONTAL_SIGNING_CONDITIONS_VALUES[HORIZONTAL_SIGNING_CONDITION_BAD].label),
    },
    [HORIZONTAL_SIGNING_CONDITION_MEDIUM]: {
        ...HORIZONTAL_SIGNING_CONDITIONS_VALUES[HORIZONTAL_SIGNING_CONDITION_MEDIUM],
        label: t(I18N_NAMESPACE, HORIZONTAL_SIGNING_CONDITIONS_VALUES[HORIZONTAL_SIGNING_CONDITION_MEDIUM].label),
    },
    [HORIZONTAL_SIGNING_CONDITION_GOOD]: {
        ...HORIZONTAL_SIGNING_CONDITIONS_VALUES[HORIZONTAL_SIGNING_CONDITION_GOOD],
        label: t(I18N_NAMESPACE, HORIZONTAL_SIGNING_CONDITIONS_VALUES[HORIZONTAL_SIGNING_CONDITION_GOOD].label),
    },
    [HORIZONTAL_SIGNING_CONDITION_UNKNOWN]: {
        color: "colors/gris/500",
        label: "",
    },
})
