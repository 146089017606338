import { useCallback, useMemo } from "react"

import { Chip } from "@mui/material"
import { useToggle } from "react-use"

import { PropTypes } from "@l2r-front/l2r-proptypes"
import { slugify } from "@l2r-front/l2r-utils"

import * as Styled from "./ChipList.styled"

export function ChipList(props) {

    const {
        className,
        onAdd,
        onDelete,
        options,
        values,
        maxDisplayedChips,
        unfoldable,
        ...chipsProps
    } = props

    const [isFolded, toggleIsFolded] = useToggle(true)

    const handleRemoveChip = useCallback((chip) => {
        const chipIndex = values.findIndex(chipFromList => {
            return chipFromList.value === chip.value
        })

        if (chipIndex >= 0) {
            return onDelete(chipIndex)
        }
    }, [onDelete, values])

    const handleFoldingChipClick = useCallback(() => {
        toggleIsFolded()
    }, [toggleIsFolded])

    const chipsToDisplay = useMemo(() => {
        const chips = values.map((chip, index) => {
            const name = typeof chip.value === "string" ? slugify(chip.value) : slugify(chip.label)

            return <Styled.Chip
                color="cta-bg/cta-bg-primary"
                key={`chip-${index}`}
                label={chip.label}
                id={`active-filter-${name}`}
                icon={chip.icon}
                {...chipsProps}
                onDelete={onDelete ? () => handleRemoveChip(chip) : null}
            />
        })

        let foldingChip = null
        if (maxDisplayedChips > 0 && chips.length > maxDisplayedChips) {
            if (isFolded) {
                const leftOverChipsCount = chips.splice(maxDisplayedChips).length
                if (leftOverChipsCount > 0) {
                    foldingChip = (
                        <Styled.Chip
                            color="cta-bg/cta-bg-primary"
                            label={`+${leftOverChipsCount}`}
                            clickable={unfoldable}
                            onClick={handleFoldingChipClick}
                        />
                    )
                }
            } else {
                foldingChip = (
                    <Styled.Chip
                        color="cta-bg/cta-bg-primary"
                        label={"<"}
                        clickable
                        onClick={handleFoldingChipClick}
                    />
                )
            }
        }

        return (
            <Styled.ChipsWrapper className={className}>
                {chips}
                {foldingChip}
                {!!onAdd && <Styled.ChipAdd
                    chipList={values.map(v => v.value)}
                    id="add-chip"
                    options={options}
                    onValueSelected={onAdd}
                />}
            </Styled.ChipsWrapper>
        )
    }, [className, values, isFolded, maxDisplayedChips, unfoldable, chipsProps, handleRemoveChip, handleFoldingChipClick, onAdd, options, onDelete])

    return chipsToDisplay
}

ChipList.propTypes = {
    ...Chip.propTypes,
    className: PropTypes.string,
    onDelete: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    onUpdate: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.string.isRequired),
    values: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            icon: PropTypes.node,
            value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
        }),
    ).isRequired,
    maxDisplayedChips: PropTypes.number,
    unfoldable: PropTypes.bool,
}

ChipList.defaultProps = {
    maxDisplayedChips: -1,
    unfoldable: false,
    variant: "outlined",
}
