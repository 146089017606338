import { useCallback, useMemo } from "react"

import { useParams } from "react-router-dom"

import { useImageCollectionDispatchContext } from "@l2r-front/l2r-images"
import {
    Layer,
    REFERENTIAL_LAYER,
    VERTICAL_SIGN_POLES_LAYER,
    VERTICAL_SIGN_POLES_SOURCE,
    VERTICAL_SIGN_POLES_LAYER_CLUSTER,
    VERTICAL_SIGN_POLES_LAYER_CLUSTER_COUNT,
    getClusterLayerConfig,
    getClusterCountLayerConfig,
    useOrderingLayers,
} from "@l2r-front/l2r-map"
import { useNetworksDispatchContext } from "@l2r-front/l2r-networks"
import { useTheme } from "@l2r-front/l2r-ui"
import { useNavigateWithSearchParams } from "@l2r-front/l2r-utils"

import { getVerticalSignPolesLayerStyle } from "../../../utils/getLayerStyle"
import { VerticalSignPolesSource } from "../VerticalSignPolesSource"

const CLUSTER_SIZE = 30
const MAX_ZOOM_LEVEL_CLUSTERED = 16

export function VerticalSignPolesLayers() {

    const theme = useTheme()
    const params = useParams()
    const { poleId } = useParams()
    const { setSelectedLinearLocations } = useNetworksDispatchContext()
    const { setSegmentFeatureIdClicked } = useImageCollectionDispatchContext()
    const road = params?.road

    useOrderingLayers(["road-number", "road-number-shield", VERTICAL_SIGN_POLES_LAYER, VERTICAL_SIGN_POLES_LAYER_CLUSTER, VERTICAL_SIGN_POLES_LAYER_CLUSTER_COUNT])

    const navigateWithSearchParams = useNavigateWithSearchParams()
    const verticalSignPolesLayerConfig = useMemo(() => {
        if (!theme) {
            return null
        }

        return getVerticalSignPolesLayerStyle(poleId, road)
    }, [poleId, road, theme])

    const verticalSignPolesClusterLayerConfig = useMemo(() => {
        if (!theme) {
            return null
        }
        return getClusterLayerConfig({
            paint: {
                "circle-color": theme.palette["colors/gris/500"].main,
                "circle-radius": CLUSTER_SIZE,
            },
        })
    }, [theme])

    const verticalSignPolesClusterCountLayerConfig = useMemo(() => {
        if (!theme) {
            return null
        }
        return getClusterCountLayerConfig({
            layout: {
                "text-size": theme.typography["H2"].fontSize,
            },
            paint: {
                "text-color": theme.palette.map.text,
            },
        })
    }, [theme])

    const handleClusterLayerClick = useCallback((event) => {
        const map = event.target

        const feature = event.feature
        const geometry = feature.geometry
        const clusterId = feature?.properties?.["cluster_id"]

        const source = map.getSource(VERTICAL_SIGN_POLES_SOURCE)
        source.getClusterExpansionZoom(clusterId, (error, zoom) => {
            if (error) {
                return
            }
            map.easeTo({
                center: geometry.coordinates,
                zoom: zoom + 1,
            })
        })
    }, [])

    const handlePoleLayerClick = useCallback((event) => {
        const map = event.target
        const roadFeatures = map.queryRenderedFeatures({
            layers: [REFERENTIAL_LAYER],
        })
        const feature = event.feature
        const roadFeatureClicked = roadFeatures.find(roadFeature => {
            const roadFeatureRoad = JSON.parse(roadFeature.properties.linearLocation)[0].road
            const roadFeatureEvent = JSON.parse(feature.properties.linearLocation).road
            return roadFeatureRoad === roadFeatureEvent
        })

        setSelectedLinearLocations(({
            ...feature,
            properties: {
                ...feature.properties,
                linearLocation: JSON.stringify([JSON.parse(feature.properties.linearLocation)]),
            },
        }))
        const linearLocation = JSON.parse(feature.properties.linearLocation)
        setSegmentFeatureIdClicked(roadFeatureClicked?.properties?.uuid)
        const newLocation = (poleId || road) ? `../${linearLocation.road}/${feature?.properties?.uuid}`
            : `${linearLocation.road}/${feature?.properties?.uuid}`

        if (poleId) {
            return navigateWithSearchParams(newLocation)
        }
        return navigateWithSearchParams(newLocation)
    }, [navigateWithSearchParams, poleId, road, setSegmentFeatureIdClicked, setSelectedLinearLocations])

    return <VerticalSignPolesSource
        id={VERTICAL_SIGN_POLES_SOURCE}
        cluster={true}
        clusterMaxZoom={MAX_ZOOM_LEVEL_CLUSTERED}>
        <Layer
            {...verticalSignPolesLayerConfig}
            id={VERTICAL_SIGN_POLES_LAYER}
            onClick={handlePoleLayerClick}
        />
        <Layer
            {...verticalSignPolesClusterLayerConfig}
            id={VERTICAL_SIGN_POLES_LAYER_CLUSTER}
            onClick={handleClusterLayerClick}
        />
        <Layer
            {...verticalSignPolesClusterCountLayerConfig}
            id={VERTICAL_SIGN_POLES_LAYER_CLUSTER_COUNT}
        />
    </VerticalSignPolesSource>
}