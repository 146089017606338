import { useNetworksStateContext } from "@l2r-front/l2r-networks"

import { verticalSigningQueryKeys } from "./queryKeys"
import { useVerticalSigningQuery } from "./useVerticalSigningQuery"

export function useVerticalSigningProject() {
    const { selectedNetwork } = useNetworksStateContext()
    const url = `projects/${selectedNetwork.slug}/`

    const queryKey = verticalSigningQueryKeys["project"]()

    return useVerticalSigningQuery(
        url,
        queryKey,
    )
}