import { keepPreviousData } from "@tanstack/react-query"

import { horizontalSigningQueryKeys } from "./queryKeys"
import { useHorizontalSigningQuery } from "./useHorizontalSigningQueries"


export function useHorizontalSigningGrades(config = {}, parameters = {}) {
    const url = "horizontal_signs/stats/conditions/"

    const queryKey = horizontalSigningQueryKeys["stats_conditions"]({ parameters })

    return useHorizontalSigningQuery(
        url,
        queryKey,
        parameters,
        {
            ...config,
            placeholderData: keepPreviousData,
        },
    )
}
