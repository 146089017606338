import { useMemo } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { useUrlParams } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../common/constants/i18n"
import { DAMAGES_URL_PREFIX_PARAM, NETWORK_ASSESMENT_TYPE_URL_PARAM, NETWORK_GRADING_PREFIX_PARAM } from "../../../common/constants/urlParams"
import { getDamagesLegendConfig } from "../constants/getDamagesConfig"
import { getNotationLegendConfig } from "../constants/getNotationConfig"
import { useRoadwayStateContext } from "../contexts/RoadwayContext"

export function useRoadwayLegend() {

    const { getParam } = useUrlParams()
    const { t } = useTranslation(I18N_NAMESPACE)
    const { selectedNetworkYearlyStatus } = useRoadwayStateContext()

    const networkGradingLegend = useMemo(() => getNotationLegendConfig(key => t(I18N_NAMESPACE, key)), [t])
    const damagesLegend = useMemo(() => getDamagesLegendConfig(key => t(I18N_NAMESPACE, key)), [t])

    const currentNetworkAssesmentType = getParam(NETWORK_ASSESMENT_TYPE_URL_PARAM)?.replace(/-(.*)/, "-") || null
    const currentDamageCode = getParam(NETWORK_ASSESMENT_TYPE_URL_PARAM)?.replace(DAMAGES_URL_PREFIX_PARAM, "") || ""
    const damageTypes = selectedNetworkYearlyStatus?.damageTypes?.find(damageType => {
        return damageType.code === currentDamageCode
    })
    const damagesLabel = damageTypes?.name ?? ""

    switch (currentNetworkAssesmentType) {
        case DAMAGES_URL_PREFIX_PARAM:
            return [
                {
                    layerName: damagesLabel,
                    legendData: damagesLegend,
                    title: t(I18N_NAMESPACE, "containers.damagesLayer.legend.title"),
                },
            ]
        case NETWORK_GRADING_PREFIX_PARAM:
            return [
                {
                    layerName: t(I18N_NAMESPACE, "containers.notationLayer.title"),
                    legendData: networkGradingLegend,
                    title: t(I18N_NAMESPACE, `containers.notationLayer.legend.title.${getParam(NETWORK_ASSESMENT_TYPE_URL_PARAM)}`),
                },
            ]
        default:
            return null
    }
}