import { useMemo } from "react"

import { Collapse, List, Typography } from "@mui/material"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./CatalogListItem.styled"

export function CatalogListItem(props) {

    const {
        children,
        count,
        defaultIcon,
        disabled,
        expandable,
        highlighted,
        icon,
        label,
        onClick,
        selected,
        totalCount,
        ItemsCountComponent,
    } = props

    const isOpen = highlighted || selected
    const displayChildren = !!(expandable && isOpen && children)
    const displayArrowIcon = !disabled && expandable

    const arrowIcon = useMemo(() => {
        return isOpen ? <Styled.ArrowUpIcon color="objects/object-placeholder" />
            : <Styled.ArrowDownIcon color="objects/object-placeholder" />
    }, [isOpen])

    return <>
        <Styled.ListItemButton key={Math.random()}
            disabled={disabled}
            highlighted={highlighted}
            onClick={(disabled || !onClick) ? undefined : onClick}
            selected={selected}
        >
            {icon && <Styled.Image
                src={icon}
                onError={e => e.target.src = defaultIcon}
            />}
            <Typography variant={(expandable && !disabled) ? "H3" : "Regular"}>{label}</Typography>
            {typeof count === "number" && <Styled.Count variant="Regular">
                {count}
            </Styled.Count>}
            {totalCount && <Typography variant="Small">
                {`/${totalCount}`}
            </Typography>}
            {ItemsCountComponent}
            {displayArrowIcon && arrowIcon}
        </Styled.ListItemButton >
        <Collapse in={displayChildren}>
            <List disablePadding>
                {children}
            </List>
        </Collapse>
    </>
}

CatalogListItem.propTypes = {
    children: PropTypes.node,
    code: PropTypes.string,
    count: PropTypes.number,
    defaultIcon: PropTypes.string,
    disabled: PropTypes.bool,
    expandable: PropTypes.bool,
    highlighted: PropTypes.bool,
    icon: PropTypes.string,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    startOpen: PropTypes.bool,
    totalCount: PropTypes.number,
    ItemsCountComponent: PropTypes.node,
}