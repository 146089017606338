import { useMemo } from "react"

import { Formik } from "formik"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { CTAButton, TagsAutocomplete } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useVerticalSigningProject } from "../../hooks/queries/verticalSigning/useVerticalSigningProject"
import * as Styled from "./VerticalSignDetailsForm.styled"

export const VerticalSignDetailsForm = (props) => {

    const {
        signTags,
        className,
        onCancel,
        onSubmit,
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)

    const { data: project } = useVerticalSigningProject()

    const existingTags = useMemo(() => {
        if (project?.tags) {
            return project.tags.sort()
        }

        return []
    }, [project])
    const initialValues = useMemo(() => {
        return ({
            tags: signTags || [],
        })

    }, [signTags])

    return <Formik
        className={className}
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={onSubmit}
    >
        {({ values }) => {
            const areInitialTags = (values.tags.length === signTags.length) && values.tags.every(tag => signTags.includes(tag))
            return <Styled.Form>
                <TagsAutocomplete 
                    name="tags"
                    variant="outlined"
                    tags={values.tags} 
                    existingTags={existingTags} />
                <Styled.ButtonsWrapper>
                    <CTAButton
                        id="cancel-button"
                        onClick={() => {
                            onCancel()
                        }}
                        variant="outlined"
                    >
                        {t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.cancel")}
                    </CTAButton>
                    <Styled.SubmitButton
                        id="submit-button"
                        color="cta-bg/cta-bg-primary"
                        type="submit"
                        variant="contained"
                        disabled={areInitialTags}
                    >
                        {t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.save")}
                    </Styled.SubmitButton>
                </Styled.ButtonsWrapper>
            </Styled.Form>
        }
        }

    </Formik>
}

VerticalSignDetailsForm.propTypes = {
    signTags: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}