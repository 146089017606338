import { styled } from "@l2r-front/l2r-ui"

import { HorizontalSigningMobileContent as HorizontalSigningContent } from "../../containers/HorizontalSigningMobileContent"
import { MobileHorizontalSigningMap as HorizontalSigningMap } from "../../containers/MobileHorizontalSigningMap"

export const HorizontalSigningMobileContent = styled(HorizontalSigningContent)(({ theme }) => ({
    paddingTop: theme.spacing(6),
}))

export const Map = styled(HorizontalSigningMap)({
    height: "100%",
    width: "100%",
})
