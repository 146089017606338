import { useCallback, useMemo } from "react"

import { useParams } from "react-router-dom"

import { SidebarContent } from "@l2r-front/l2r-ui"
import { useNavigateWithSearchParams } from "@l2r-front/l2r-utils"

import { DETAIL_TAB_PARAM_KEY } from "../../constants/tabKeys"
import { useHorizontalSign } from "../../hooks/queries/horizontalSigning/useHorizontalSigns"
import { useHorizontalSignType } from "../../hooks/queries/horizontalSigning/useHorizontalSignTypes"
import { HorizontalSignDetailsSidebarContent } from "../HorizontalSignDetailsSidebarContent"

export function HorizontalSignDetailsSidebar() {

    const navigate = useNavigateWithSearchParams()
    const { signId, road } = useParams()
    const { data: sign } = useHorizontalSign(signId)
    const { data: signType } = useHorizontalSignType(sign?.horizontal_sign_type, {}, {enabled: !!sign})

    const title = useMemo(() => {
        return signType?.name ? signType.name : ""
    }, [signType])

    const navigateBack = useCallback(() => {
        navigate(`../${road}`, [DETAIL_TAB_PARAM_KEY])
    }, [navigate, road])

    return <SidebarContent id="horizontalSignDetails-sidebar"
        onBack={navigateBack}
        title={title}
    >
        <HorizontalSignDetailsSidebarContent />
    </SidebarContent>
}