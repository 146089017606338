import { useNetworksStateContext } from "@l2r-front/l2r-networks"
import { EMPTY_QUERY_PARAM } from "@l2r-front/l2r-query"

import { verticalSigningQueryKeys } from "./queryKeys"
import { useVerticalSigningQuery } from "./useVerticalSigningQuery"

const REFETCH_INTERVAL = 7 * 60 * 1000

function sortChildTypes(parent) {
    parent.children.sort((a, b) => a.code.localeCompare(b.code))

    parent.children.forEach(child => sortChildTypes(child))
}

export function useVerticalSignTypes(parent = EMPTY_QUERY_PARAM, config = {}) {
    const url = "vertical_sign_types/"
    const { selectedNetwork } = useNetworksStateContext()
    const { country } = selectedNetwork

    const parameters = {
        country,
        parent: parent || EMPTY_QUERY_PARAM,
    }

    const queryKey = verticalSigningQueryKeys.list(parameters)

    const { data, isLoading, isError, isFetching } = useVerticalSigningQuery(
        url,
        queryKey,
        parameters,
        {
            ...config,
            refetchInterval: REFETCH_INTERVAL,
            refetchIntervalInBackground: true,
            staleTime: REFETCH_INTERVAL,
        },
    )

    if (data) {
        data.sort((a, b) => a.code.localeCompare(b.code))
        data.forEach(d => sortChildTypes(d))
    }

    return { data, isLoading, isError, isFetching }
}

export function useVerticalSignType(code, queryParameters, config = {}) {
    const url = `vertical_sign_types/${code}/`
    const queryKey = verticalSigningQueryKeys["verticalSignType"]({ ...queryParameters, code })

    const { data, isLoading, isError } = useVerticalSigningQuery(
        url,
        queryKey,
        { ...queryParameters },
        {
            enabled: !!code,
            ...config,
        },
    )

    if (data) {
        sortChildTypes(data)
    }

    return { data, isLoading, isError }
}