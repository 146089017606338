import { useParams } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import {
    CatalogItemsGrading,
    CatalogItemsGradingError,
    CatalogItemsGradingSkeleton,
} from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { getHorizontalSigningConditionsValues } from "../../constants/horizontalSigningConditions"
import { useHorizontalSigningDispatchContext, useHorizontalSigningStateContext } from "../../contexts/HorizontalSigningContext/HorizontalSigningContext.hooks"
import { useHorizontalSigningGrades } from "../../hooks/queries/horizontalSigning/useHorizontalSigningGrades"

export function HorizontalSignsGrading(props) {
    const { road } = useParams()
    const { filters } = useHorizontalSigningStateContext()
    const { setFilters } = useHorizontalSigningDispatchContext()
    const { t } = useTranslation(I18N_NAMESPACE)

    const { data, isError, isLoading } = useHorizontalSigningGrades({}, {
        ...(road && { linear_location_road: road }),
    })

    if (isError) {
        return <CatalogItemsGradingError
            errorText={t(I18N_NAMESPACE, "components.horizontalSignsGrading.error")}
            {...props}
        />
    }

    if (!data && isLoading) {
        return <CatalogItemsGradingSkeleton {...props} />
    }

    return <CatalogItemsGrading
        activeConditionFilters={filters?.conditions}
        catalogItemsGradingSettings={getHorizontalSigningConditionsValues(t)}
        count={data.count}
        onConditionFiltersChanged={(value) => setFilters({ condition: value })}
        {...props}
    />
}