import { useMemo } from "react"

import { useParams } from "react-router-dom"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { useHorizontalSigningStateContext } from "../../contexts/HorizontalSigningContext"
import { useHorizontalSigningCategoriesStats } from "../../hooks/queries/horizontalSigning/useHorizontalSigningCategoriesStats"
import { useHorizontalSignTypes } from "../../hooks/queries/horizontalSigning/useHorizontalSignTypes"
import * as Styled from "./HorizontalSignsCount.styled"

export function HorizontalSignsCount(props) {

    const {
        className,
        displayTotalCount,
        isMainCount,
        signType,
    } = props

    const { road } = useParams()
    const { filters } = useHorizontalSigningStateContext()

    const apiFilters = {
        ...(filters?.conditions?.length && { condition: filters?.conditions }),
        ...(road && { linear_location_road: road }),
    }

    const { data: dataWithFilters,
        isError: dataWithFiltersError,
        isLoading: dataWithFiltersLoading,
    } = useHorizontalSigningCategoriesStats({}, { ...apiFilters })

    const {
        data: dataWithoutConditionFilters,
        isError: dataWithoutConditionFiltersError,
        isLoading: dataWithoutConditionFiltersLoading,
    } = useHorizontalSigningCategoriesStats({}, { ...(road && { linear_location_road: road }) })


    const {
        data: horizontalSignTypes,
        isError: isErrorHorizontalSignTypes,
        isLoading: isLoadingHorizontalSignTypes,
    } = useHorizontalSignTypes()

    const calculatedCount = useMemo(() => {
        if (dataWithFilters && horizontalSignTypes) {
            return horizontalSignTypes.reduce((acc, verticalSignType) => {
                return acc + dataWithFilters.count[verticalSignType.code]
            }, 0)
        }
    }, [dataWithFilters, horizontalSignTypes])

    if (dataWithFiltersError || dataWithoutConditionFiltersError || isErrorHorizontalSignTypes) {
        return null
    }

    if (dataWithFiltersLoading || dataWithoutConditionFiltersLoading || isLoadingHorizontalSignTypes) {
        return <Styled.CatalogItemsCountSkeleton className={className} isMainCount={isMainCount} />
    }

    return <Styled.CatalogItemsCount
        className={className}
        filteredCount={signType ? dataWithFilters.count[signType] : calculatedCount}
        isMainCount={isMainCount}
        totalCount={displayTotalCount && dataWithoutConditionFilters?.count?.[signType]}
    />
}

HorizontalSignsCount.propTypes = {
    className: PropTypes.string,
    displayTotalCount: PropTypes.bool,
    isMainCount: PropTypes.bool,
    signType: PropTypes.string,
}