import { useQueryClient } from "@tanstack/react-query"

import { l2rApiAxiosInstance as axiosInstance } from "@l2r-front/l2r-query"

import { verticalSigningQueryKeys } from "../queries/verticalSigning/queryKeys"
import { useVerticalSigningMutation } from "./useVerticalSigningMutation"

export function useVerticalSignUpdate(
    signId,
    poleId,
    queryParameters,
    config = {},
) {
    const url = `vertical_signs/${signId}/`
    const queryClient = useQueryClient()

    return useVerticalSigningMutation(
        url,
        axiosInstance.patch,
        {
            ...queryParameters,
        },
        {
            ...config,
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: verticalSigningQueryKeys.verticalSign(signId) })
                queryClient.invalidateQueries({ queryKey: verticalSigningQueryKeys.pole(poleId) })
                queryClient.invalidateQueries({ queryKey: verticalSigningQueryKeys.project() })
            },
        },
    )
}