import { useNetworksStateContext } from "@l2r-front/l2r-networks"

import { horizontalSigningQueryKeys } from "./queryKeys"
import { useHorizontalSigningQuery } from "./useHorizontalSigningQueries"

export function useHorizontalSigningProject() {
    const { selectedNetwork } = useNetworksStateContext()
    const url = `projects/${selectedNetwork.slug}/`

    const queryKey = horizontalSigningQueryKeys["project"]()

    return useHorizontalSigningQuery(
        url,
        queryKey,
    )
}