import { queryKeyFactory } from "@l2r-front/l2r-query"

export const VERTICAL_SIGNS_ROOT_QUERY_KEY = "verticalSigning"

export const verticalSigningQueryKeys = queryKeyFactory(VERTICAL_SIGNS_ROOT_QUERY_KEY, {
    layer: (filters = {}) => [VERTICAL_SIGNS_ROOT_QUERY_KEY, "layer", filters],
    pole: (poleId, params = {}) => [VERTICAL_SIGNS_ROOT_QUERY_KEY, "pole", poleId, params],
    project: (filters = {}) => [VERTICAL_SIGNS_ROOT_QUERY_KEY, "project", filters],
    stats_categories: (parameters) => [VERTICAL_SIGNS_ROOT_QUERY_KEY, "stats", "categories", { parameters }],
    stats_conditions: (parameters) => [VERTICAL_SIGNS_ROOT_QUERY_KEY, "stats", "conditions", { parameters }],
    verticalSignType: (code) => [VERTICAL_SIGNS_ROOT_QUERY_KEY, "verticalSignType", code],
    verticalSign: (uuid) => [VERTICAL_SIGNS_ROOT_QUERY_KEY, "verticalSign", uuid],
})
