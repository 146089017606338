import { keepPreviousData } from "@tanstack/react-query"

import { horizontalSigningQueryKeys } from "./queryKeys"
import { useHorizontalSigningQuery } from "./useHorizontalSigningQueries"


export function useHorizontalSigningCategoriesStats(config = {}, parameters = {}) {
    const url = "horizontal_signs/stats/horizontal_sign_types/"

    const queryKey = horizontalSigningQueryKeys["stats_categories"]({ parameters })

    return useHorizontalSigningQuery(
        url,
        queryKey,
        parameters,
        {
            ...config,
            placeholderData: keepPreviousData,
        },
    )
}