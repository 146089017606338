import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./CatalogItemsGrading.styled"

export function CatalogItemsGradingError(props) {

    const {
        errorText,
        ...otherProps
    } = props


    return <div {...otherProps}>
        <Styled.ErrorText>{errorText}</Styled.ErrorText>
    </div>
}

CatalogItemsGradingError.propTypes = {
    errorText: PropTypes.string.isRequired,
}