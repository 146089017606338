import { CatalogListItemSkeleton } from "../CatalogListItem"
import * as Styled from "./CatalogList.styled"

export function CatalogListSkeleton(props) {

    return <div {...props}>
        <Styled.TitleSkeleton />
        {Array.from({ length: 5 })
            .map((_, index) => {
                return <CatalogListItemSkeleton key={`catalogItemSkeleton-${index}`} />
            })}
    </div>
}