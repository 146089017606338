import { Skeleton as UiSkeleton, styled, Typography } from "@mui/material"

const BORDER_RADIUS = 8
const HEIGHT = 8

export const Mask = styled("div")(({ theme }) => ({
    borderRadius: BORDER_RADIUS,
    height: "fit-content",
    overflow: "hidden",
    width: "100%",
    marginBottom: theme.spacing(3),
}))

export const CatalogItemsGradingBarWrapper = styled("div", {
    shouldForwardProp: (prop) => prop !== "itemsCount",
})(({ itemsCount }) => {
    const padding = (itemsCount - 1) < 0 ? 0 :
        (itemsCount - 1) * BORDER_RADIUS
    return ({
        display: "flex",
        height: HEIGHT,
        position: "relative",
        width: `calc(100% + ${padding}px)`,
    })
})

export const EmptyBarItem = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette["surfaces/surface-grey"].main,
    borderRadius: BORDER_RADIUS,
    display: "flex",
    height: HEIGHT,
    marginBottom: theme.spacing(3),
    position: "absolute",
    width: "100%",
}))

export const SignsGradingBarItem = styled("div", {
    shouldForwardProp: (prop) => !["color", "index", "width"].includes(prop),
})(({ color, index, theme, width }) => ({
    backgroundColor: theme.palette[color].main,
    borderRadius: BORDER_RADIUS,
    height: HEIGHT,
    transform: `translateX(${index * -BORDER_RADIUS}px)`,
    width: `${width}%`,
    zIndex: 3 - index,
}))

export const CatalogItemsCountsWrapper = styled("div")({
    display: "flex",
    justifyContent: "space-between",
})

export const Skeleton = styled(UiSkeleton)({
    height: 91,
    transform: "scale(1)",
    width: "100%",
})

export const ErrorText = styled(Typography)(({ theme }) => ({
    color: theme.palette["texts/text-error"].main,
    textAlign: "center",
}))