import { Route, Routes } from "react-router-dom"

import { HorizontalSigningMapLayersRouter } from "../../../modules/horizontalSigning/routers/HorizontalSigningMapLayersRouter"
import { RoadwayMapLayersRouter } from "../../../modules/roadway/routers/RoadwayMapLayersRouter"
import { RoadworksMapLayersRouter } from "../../../modules/roadworks/routers/RoadworksMapLayersRouter"
import { MODULE_HORIZONTAL_SIGNING, MODULE_ROADWAY, MODULE_ROADWORKS } from "../../constants/modules"

export const RoadDesktopMapRouter = () => {

    return (
        <Routes>
            <Route
                element={
                    <RoadwayMapLayersRouter />
                }
                path={`/${MODULE_ROADWAY}/*`}
            />
            <Route
                element={<RoadworksMapLayersRouter />
                }
                path={`/${MODULE_ROADWORKS}/*`}
            />
            <Route
                element={<HorizontalSigningMapLayersRouter />
                }
                path={`/${MODULE_HORIZONTAL_SIGNING}/*`}
            />
        </Routes>
    )
}
