import { findTypeByCode } from "../../../utils/findTypeByCode"
import { useHorizontalSigns } from "./useHorizontalSigns"
import { useHorizontalSignTypes } from "./useHorizontalSignTypes"

export function useHorizontalSignTypesInProject() {
    const { data: horizontalSignTypes, isLoading: isHorizontalSignTypesLoading, isError: isHorizontalSignTypesError } = useHorizontalSignTypes()
    const { data: horizontalSigns, isLoading: isHorizontalSignsLoading, isError: isHorizontalSignsError } = useHorizontalSigns()

    const isLoading = isHorizontalSignTypesLoading || isHorizontalSignsLoading
    const isError = isHorizontalSignTypesError || isHorizontalSignsError

    if (horizontalSignTypes && horizontalSigns) {
        const typeCodesInPoles = new Set()
        horizontalSigns.forEach(sign => {
            typeCodesInPoles.add(sign.horizontal_sign_type)
        })

        const typeUsedInProject = []
        for (const code of typeCodesInPoles) {
            const type = findTypeByCode(code, horizontalSignTypes)
            typeUsedInProject.push(type)
        }

        return { data: typeUsedInProject, isLoading, isError }
    }

    return { data: null, isLoading, isError }
}