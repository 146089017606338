import { queryKeyFactory } from "@l2r-front/l2r-query"

export const HORIZONTAL_SIGNS_ROOT_QUERY_KEY = "horizontalSign"

export const horizontalSigningQueryKeys = queryKeyFactory(HORIZONTAL_SIGNS_ROOT_QUERY_KEY, {
    layer: (filters = {}) => [HORIZONTAL_SIGNS_ROOT_QUERY_KEY, "layer", filters],
    feature: (featureId, params = {}) => [HORIZONTAL_SIGNS_ROOT_QUERY_KEY, "feature", featureId, params],
    project: (filters = {}) => [HORIZONTAL_SIGNS_ROOT_QUERY_KEY, "project", filters],
    stats_categories: (parameters) => [HORIZONTAL_SIGNS_ROOT_QUERY_KEY, "stats", "categories", { parameters }],
    stats_conditions: (parameters) => [HORIZONTAL_SIGNS_ROOT_QUERY_KEY, "stats", "conditions", { parameters }],
    horizontalSignType: (code) => [HORIZONTAL_SIGNS_ROOT_QUERY_KEY, "horizontalSignType", code],
})
