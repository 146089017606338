export function findTypeByCode(code, horizontalSignTypes) {
    if (!horizontalSignTypes) {
        return null
    }

    for (const type of horizontalSignTypes) {
        if (type.code === code) {
            return type
        } else {
            const typeFound = findTypeByCode(code, type.children)
            if (typeFound) {
                return typeFound
            }
        }
    }

    return null
}