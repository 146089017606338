import { useMemo } from "react"

import { useParams } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { RoadDetailInfo } from "@l2r-front/l2r-networks"
import { Typography } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useHorizontalSign } from "../../hooks/queries/horizontalSigning/useHorizontalSigns"
import { HorizontalSignDetails } from "../HorizontalSignDetails"
import * as Styled from "./HorizontalSignDetailsSidebarContent.styled"

export function HorizontalSignDetailsSidebarContent() {

    const { signId, road } = useParams()
    const { data, isError } = useHorizontalSign(signId)

    const { t } = useTranslation(I18N_NAMESPACE)

    const latLong = data?.geometry.coordinates

    const LatLongComponent = useMemo(() => {
        if (latLong) {
            return <Typography id="road-detail-latlong" variant="Regular">
                {latLong[0].toFixed(6)}, {latLong[1].toFixed(6)}
            </Typography>
        }
        return <Styled.Skeleton />
    }, [latLong])

    return <Styled.Container>
        <Styled.RoadDetailWrapper>
            <Typography variant="H3">
                {t(I18N_NAMESPACE, "containers.horizontalSignDetailSidebar.location.title")}
            </Typography>
            <RoadDetailInfo
                LengthComponent={LatLongComponent}
                lengthLabel={`${t(I18N_NAMESPACE, "containers.horizontalSignDetailSidebar.location.latlong")} `}
                road={road}
            />
        </Styled.RoadDetailWrapper>
        <Styled.Wrapper>
            {
                isError ? <Styled.ErrorText>
                    {t(I18N_NAMESPACE, "containers.horizontalSignDetailSidebar.content.error")}
                </Styled.ErrorText>
                    : <HorizontalSignDetails horizontalSign={data} />
            }
        </Styled.Wrapper>
    </Styled.Container>
}